import React from "react";


import Box from "@cloudscape-design/components/box";
import Button from "@cloudscape-design/components/button";
import Container from "@cloudscape-design/components/container";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import SpaceBetween from "@cloudscape-design/components/space-between";


export function Login() {
    return (
        <div>
        <Container>
            <img src="./logo_werteck.png" />
        </Container>

        <div className = "content__boxed w-100 min-vh-100 d-flex flex-column align-items-center justify-content-center">
          <div>
            <SpaceBetween size="xxl">
              <Box fontSize="heading-s" fontWeight="bold" >
                Sign in
              </Box>

              <div>
              <SpaceBetween size="s">
              <FormField
                label="Root user email address"
              ></FormField>

              <Input
                value=""
                type="text"
                inputMode="email"
               />

            <FormField
                label="Password"
              ></FormField>

              <Input
              value=""
                type="password"
               />
               <Button variant="primary">Sign In</Button>


              </SpaceBetween>
              

              </div>
              
            </SpaceBetween>
            
          </div>
        </div>
        </div>
    );
}
