/**
 * @ description Module show Error page
 */

import React from "react";
//import "@cloudscape-design/global-styles/index.css";
import "../../assets/premium-solid-icons.css"
import "./Maintainence.css"
import SpaceBetween from "@cloudscape-design/components/space-between";
import Badge from "@cloudscape-design/components/badge";
import TextContent from "@cloudscape-design/components/text-content";




export function Maintainence() {
  return (

    <div className = "content justify-content-center">
      <div className="text-center">
        <SpaceBetween size="m">
          <div className="mb-4">
            <i className="psi-refinery error-code text-gray-600"></i>
          </div>
          <h3 className="mb-5">
              <Badge color="blue">Website under construction...</Badge>
          </h3>   
        </SpaceBetween>
      </div>  
      
      <TextContent>
        <div className="text-center">
        <p>Our website is currently under construction. <br /> We should be back shortly to serve you with an awesome experience.</p>
        </div>
      </TextContent>
    </div>
  );

}
