import React from "react";
import Form from "@cloudscape-design/components/form";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import Container from "@cloudscape-design/components/container";
import Header from "@cloudscape-design/components/header";
import FormField from "@cloudscape-design/components/form-field";
import Input from "@cloudscape-design/components/input";
import Box from "@cloudscape-design/components/box";
import Wizard from "@cloudscape-design/components/wizard";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import Link from "@cloudscape-design/components/link";
import Select from "@cloudscape-design/components/select";

export function Registration() {
    const [
        selectedOption,
        setSelectedOption
      ] = React.useState({ label: "Individual", value: "individual" });
    return (
        
        
        <div className = "content__boxed w-100 min-vh-100 d-flex flex-column align-items-center justify-content-center">
          <div className="mb-4">
            
            <i className="psi-add-user error-code-md     text-teal-400"></i>
          </div>
          
          <ColumnLayout columns={2} variant="text-grid">
            <div>
                <i className="psi-gears-2 error-code-sm  text-teal-400"></i> Create Awesom
             
            </div>
            <div>
            <SpaceBetween size="xxl">
              <Box fontSize="heading-s" fontWeight="bold" >
                Register as a New User
              </Box>
              
              <FormField
                description="Do you want to register as an individual or as an organisation"
                label="Account Role"
              >
              <Select
                selectedOption={selectedOption}
                options={[
                    { label: "Individual", value: "individual" },
                    { label: "Organization", value: "organization" }
                    
                ]}
                selectedAriaLabel="Selected"
              />
              </FormField>
              <FormField
                description="Used for administrative functions and WerteckDB workflow initiation."
                label="Root user email address"
              >
                 <Input
                value="1"
                inputMode="text"
                
               />

              </FormField>
              <FormField
                description="Choose a name for your account. This will be used to create a subdomain for your account."
                label="WerteckDB account name"
              >
                 <Input
                value="1"
                inputMode="text"
                
               />

              </FormField>
              <Button variant="primary">Verify account information</Button>
              </SpaceBetween>

            </div>
          </ColumnLayout>
        </div>
      );

}