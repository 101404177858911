import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Maintainence } from './components/Support/Maintainence';
import { Registration } from './pages/Registration/Registration';
import { Login } from './pages/Login/Login';

function App() {
  return (
    <BrowserRouter>
    <Routes>
        <Route path="/maintainence"element={<Maintainence/>} />
    </Routes>
    <Routes>
        <Route path="/registration"element={<Registration/>} />
    </Routes>
    <Routes>
        <Route path="/login"element={<Login/>} />
    </Routes>
    </BrowserRouter>
  );
}

export default App;
